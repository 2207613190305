import React from 'react';

import clsx from 'clsx';

import styles from './Underline.module.css';

interface UnderlineProps {
    variant?: 'orange' | 'max-orange';
    size?: 'small';
}

function Underline({ children, variant, size, ...otherProps }: React.PropsWithChildren<UnderlineProps>): React.ReactElement {
    return (
        <u
            className={clsx(styles.underline, variant ? styles[variant] : null, size ? styles[size] : null)}
            {...otherProps}
        >
            {children}
        </u>
    );
}

export default Underline;
