export function renderItem(item, search) {
    search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
    const re = new RegExp(`(.*?\\s|^)(${search})(.*)`, 'gi');
    const match = re.exec(item);
    if (match === null) {
        return <>{item}</>;
    }
    return (
        <>
            {match[1]}
            {match[2]}
            <em>{match[3]}</em>
        </>
    );
}
/**
 *
 * @param {string} str
 * @param {component|html|string} replacement
 * @usage   const User = ({ href, name }) => {
                return (
                    <a href={href} title={name}>
                        {name}
                    </a>
                );
            };

            const string = 'Hello {component}, {html}, {string}';

            return (
                <div>
                    {replaceJSX(string, {
                        component: (
                            <User
                                href='#'
                                name='Lorem Ipsum'
                            />
                        ),
                        html: (
                            <span style={{ fontWeight: 'bold' }}>
                                This would be your solution
                            </span>
                        ),
                        string: 'Enjoy!',
                    })}
                </div>
            )
 * @returns np.: <div>Hello <a href="https://stackoverflow.com/users/64730/magnus-engdal" title="Lorem Ipsum">Lorem Ipsum</a>, <span style="font-weight: bold;">This would be your solution.</span>, Enjoy!.</div>
 */
export const replaceJSX = (str, replacement) => {
    const result = [];
    const keys = Object.keys(replacement);
    const getRegExp = () => {
        const regexp = [];
        keys.forEach((key) => regexp.push(`${key}`));
        return new RegExp(regexp.join('|'));
    };
    str.split(getRegExp()).forEach((item, i) => {
        result.push(item, replacement[keys[i]]);
    });
    return result;
};

export const addPageInfoToSerachParams = (queryParams, url) => {
    if (url !== undefined) {
        const urlStartFreePrepare = new URL(url);
        queryParams && queryParams.pageinfo && urlStartFreePrepare.searchParams.set('pageinfo', queryParams.pageinfo);
        return urlStartFreePrepare.toString();
    }
};

export const addQueryParamsToSerachParams = (queryParams, url) => {
    if (url !== undefined) {
        const urlStartFreePrepare = new URL(url);

        Object.entries(queryParams).map((v) => {
            queryParams && v[1] && urlStartFreePrepare.searchParams.append(v[0], v[1]);
        });

        return urlStartFreePrepare.toString();
    }
};
