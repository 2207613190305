import React from 'react';

import { useRouter } from 'next/router';

import { useAppContext } from '@context/AppContext';

import CustomLink from '@components/global/CustomLink';

import { addQueryParamsToSerachParams } from '@helpers/utils';

import { laravelRouteStartFree } from '@static_components/laravelLinks';
import { pageinfo } from '@static_components/pageinfo';
import clsx from 'clsx';

import styles from './Button.module.css';

interface CTAButtonStartFreeCreateAccountProps {
    color?: 'yellow' | 'black' | 'orange' | 'blue' | 'ghost' | 'max-orange' | 'ghost-blue' | 'max-black' | 'black-white';
    label: string | JSX.Element;
    size?: 'small' | 'medium' | 'large';
    className?: string;
    arrow?: boolean;
    onClick?: () => void | undefined;
    params?: {
        pageinfo?: string | undefined | null;
        code?: string | null | undefined;
        version?: string | null;
    } | null;
    link?: string | null;
}

export const CTAStartFreeCreateAccount = ({ link = null, color = 'yellow', label, size = 'medium', className, arrow = true, params = null, ...otherProps }: CTAButtonStartFreeCreateAccountProps) => {
    let prepareLinkToStartFree;
    if (link === null) {
        const { locale } = useRouter();
        const { pageProperties } = useAppContext();

        if ((params && params?.pageinfo === undefined) || params?.pageinfo === null) {
            let newParams = {
                ...params,
                ...pageProperties,
            };
            prepareLinkToStartFree = addQueryParamsToSerachParams(newParams, laravelRouteStartFree(locale));
        } else {
            if (params !== null) {
                const isUndefinedPathInfo = Object.keys(params).find((key) => key === 'pathinfo');
                if (isUndefinedPathInfo === undefined) {
                    let newParams = {
                        ...pageProperties,
                        ...params,
                    };
                    prepareLinkToStartFree = addQueryParamsToSerachParams(newParams, laravelRouteStartFree(locale));
                } else {
                    prepareLinkToStartFree = addQueryParamsToSerachParams(params, laravelRouteStartFree(locale));
                }
            } else {
                prepareLinkToStartFree = addQueryParamsToSerachParams(pageProperties, laravelRouteStartFree(locale));
            }
        }
    } else {
        prepareLinkToStartFree = link;
    }

    return (
        <a
            href={prepareLinkToStartFree}
            className={clsx(styles.btn, className, styles[size], styles[color], arrow ? styles.arrow : null)}
            {...otherProps}
        >
            <span>{label}</span>
        </a>
    );
};
