import { memo, useMemo } from 'react';

import { useRouter } from 'next/router';

import { NextSeo } from 'next-seo';

import { alternates } from '@helpers/alternates';
import { htmlLang } from '@helpers/languageList';

import socialShare from '@public/img_share_hp.png';

const Seo = (props) => {
    const router = useRouter();
    const pathname = router.pathname.replace(/^\/\[locale\]/i, '');
    const alternateArr = useMemo(() => alternates(pathname, props.alternateQuery), [props.alternateQuery, pathname]);

    const canonical = typeof props.canonical !== 'undefined' ? { href: props.canonical } : alternateArr.find((hreflang) => hreflang.hrefLang === (router.locale === 'en' ? 'x-default' : router.locale.replace('vn', 'vi')));
    const canonicalUrl = canonical ? canonical.href : null;

    const alternateUrls = props.languageAlternates ? props.languageAlternates : canonicalUrl ? alternateArr : null;
    const seo = {
        title: '',
        description: '',
        twitter: {
            site: '@getresponse',
            cardType: 'summary_large_image',
        },
        dangerouslyDisableGooglebot: true,
        additionalMetaTags: [
            {
                httpEquiv: 'content-language',
                content: htmlLang(router.locale).HTML_LANG,
            },
        ],
        openGraph: {
            type: 'website',
            title: props.ogTitle ?? props.title,
            description: props.ogDescription ?? props.description,
            image: props.ogImage ?? null,
        },
        ...props,
        languageAlternates: alternateUrls,
        canonical: canonicalUrl,
    };

    if (props.ogImage) {
        seo.openGraph.images = [{ url: props.ogImage }];
    }

    if (typeof seo.openGraph.url === 'undefined') {
        seo.openGraph.url = canonicalUrl;
    }
    if (typeof seo.openGraph.images === 'undefined') {
        seo.openGraph.images = [{ url: socialShare.src }];
    }
    return <NextSeo {...seo} />;
};

export default memo(Seo, (oldProps, newProps) => {
    return JSON.stringify(oldProps) === JSON.stringify(newProps);
});
