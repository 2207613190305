export function htmlDir(locale = 'en') {
    let htmlDir = '';
    switch (locale) {
        case 'he':
            htmlDir = 'rtl';
            break;
        default:
            htmlDir = 'ltr';
            break;
    }
    return htmlDir;
}

export function htmlLang(locale = 'en') {
    const langList = {
        en: {
            ID: 1,
            LANG: 'en',
            COUNTRY_CODE: 'en',
            HTML_LANG: 'en',
            LANGUAGE_NAME: 'English',
            HREFLANG: 'en',
            PREFIX: '',
        },
        pl: {
            ID: 2,
            LANG: 'pl',
            COUNTRY_CODE: 'pl',
            HTML_LANG: 'pl',
            LANGUAGE_NAME: 'Polski',
            HREFLANG: 'pl',
            PREFIX: '',
        },
        de: {
            ID: 3,
            LANG: 'de',
            COUNTRY_CODE: 'de',
            HTML_LANG: 'de',
            LANGUAGE_NAME: 'Deutsch',
            HREFLANG: 'de',
            PREFIX: 'de',
        },
        es: {
            ID: 4,
            LANG: 'es',
            COUNTRY_CODE: 'es',
            HTML_LANG: 'es',
            LANGUAGE_NAME: 'Español',
            HREFLANG: 'es',
            PREFIX: 'es',
        },
        fr: {
            ID: 5,
            LANG: 'fr',
            COUNTRY_CODE: 'fr',
            HTML_LANG: 'fr',
            LANGUAGE_NAME: 'Française',
            HREFLANG: 'fr',
            PREFIX: 'fr',
        },
        pt: {
            ID: 6,
            LANG: 'pt',
            COUNTRY_CODE: 'pt-pt',
            HTML_LANG: 'pt',
            LANGUAGE_NAME: 'Português',
            HREFLANG: 'pt-pt',
            PREFIX: 'pt',
        },
        ru: {
            ID: 9,
            LANG: 'ru',
            COUNTRY_CODE: 'ru',
            HTML_LANG: 'ru',
            LANGUAGE_NAME: 'Русский',
            HREFLANG: 'ru',
            PREFIX: '',
        },
        it: {
            ID: 10,
            LANG: 'it',
            COUNTRY_CODE: 'it',
            HTML_LANG: 'it',
            LANGUAGE_NAME: 'Italiano',
            HREFLANG: 'it',
            PREFIX: 'it',
        },
        jp: {
            ID: 11,
            LANG: 'jp',
            COUNTRY_CODE: 'ja',
            HTML_LANG: 'ja',
            LANGUAGE_NAME: '日本語',
            HREFLANG: 'ja',
            PREFIX: '',
        },
        zh: {
            ID: 12,
            LANG: 'zh',
            COUNTRY_CODE: 'zh',
            HTML_LANG: 'zh',
            LANGUAGE_NAME: '简体中文',
            HREFLANG: 'zh',
            PREFIX: '',
        },
        sv: {
            ID: 13,
            LANG: 'sv',
            COUNTRY_CODE: 'sv',
            HTML_LANG: 'sv',
            LANGUAGE_NAME: 'Svenska',
            HREFLANG: 'sv',
            PREFIX: '',
        },
        no: {
            ID: 14,
            LANG: 'no',
            COUNTRY_CODE: 'no',
            HTML_LANG: 'no',
            LANGUAGE_NAME: 'Norsk',
            HREFLANG: 'no',
            PREFIX: '',
        },
        ms: {
            ID: 15,
            LANG: 'ms',
            COUNTRY_CODE: 'ms',
            HTML_LANG: 'ms',
            LANGUAGE_NAME: 'Melayu',
            HREFLANG: 'ms',
            PREFIX: '',
        },
        th: {
            ID: 16,
            LANG: 'th',
            COUNTRY_CODE: 'th',
            HTML_LANG: 'th',
            LANGUAGE_NAME: 'ภาษาไทย',
            HREFLANG: 'th',
            PREFIX: '',
        },
        ko: {
            ID: 17,
            LANG: 'ko',
            COUNTRY_CODE: 'ko',
            HTML_LANG: 'ko',
            LANGUAGE_NAME: '한국어',
            HREFLANG: 'ko',
            PREFIX: '',
        },
        tr: {
            ID: 18,
            LANG: 'tr',
            COUNTRY_CODE: 'tr',
            HTML_LANG: 'tr',
            LANGUAGE_NAME: 'Türkçe',
            HREFLANG: 'tr',
            PREFIX: '',
        },
        ro: {
            ID: 19,
            LANG: 'ro',
            COUNTRY_CODE: 'ro',
            HTML_LANG: 'ro',
            LANGUAGE_NAME: 'Română',
            HREFLANG: 'ro',
            PREFIX: '',
        },
        hu: {
            ID: 20,
            LANG: 'hu',
            COUNTRY_CODE: 'hu',
            HTML_LANG: 'hu',
            LANGUAGE_NAME: 'Magyar',
            HREFLANG: 'hu',
            PREFIX: 'hu',
        },
        vn: {
            ID: 22,
            LANG: 'vi',
            COUNTRY_CODE: 'vi',
            HTML_LANG: 'vi',
            LANGUAGE_NAME: 'Vietnamese',
            HREFLANG: 'vi',
            PREFIX: '',
        },
        id: {
            ID: 23,
            LANG: 'id',
            COUNTRY_CODE: 'id',
            HTML_LANG: 'id',
            LANGUAGE_NAME: 'Bahasa Indonesia',
            HREFLANG: 'id',
            PREFIX: '',
        },
        nl: {
            ID: 24,
            LANG: 'nl',
            COUNTRY_CODE: 'nl',
            HTML_LANG: 'nl',
            LANGUAGE_NAME: 'Nederlands',
            HREFLANG: 'nl',
            PREFIX: '',
        },
        dk: {
            ID: 25,
            LANG: 'dk',
            COUNTRY_CODE: 'da',
            HTML_LANG: 'da',
            LANGUAGE_NAME: 'Dansk',
            HREFLANG: 'da',
            PREFIX: 'dk',
        },
    };

    return langList[locale];
}
